<!-- 专项讲解 -- 学生反馈 -->
<template>
  <div style="background: rgba(247, 247, 247, 1);padding-right: 40rem;min-height: 100%">
    <div class="title">
      <div class="title-bottom" style="padding-top: 24rem">
        <div class="flex" style="font-weight: bold">
          <div>
            <span class="span1">讲解教师：</span>
            <span class="span2">{{ dataCont.teacher_name }}</span>
          </div>
          <div style="margin-left: 100rem">
            <span class="span1">讲解科目：</span>
            <span class="span2">{{ dataCont.subject_name }}</span>
          </div>
          <div style="margin-left: 100rem">
            <span class="span1">讲解时间：</span>
            <span class="span2">{{ dataCont.solve_time }}</span>
          </div>
        </div>
        <div class="flex" style="font-weight: bold;margin-top: 12rem">
          <div>
            <span class="span1">讲解标题：</span>
            <span class="span2">{{ dataCont.title }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="title">
      <div class="title-bottom">
        <list-template
            style="padding-top: 10rem;padding-left: 0"
            :loading="loading"
            :search-config="searchConfig"
            @onSearch="onSearch"
            :table-config="tableConfig"
            :table-data="tableData"
            :total="total"
            :isDownload="true"
            @onDownload="getData(1)"
            :current-page="page"
            @onChangePage="handleCurrentChange"
            @onHandle="handleEdit"
            @onReset="onReset"
        ></list-template>
      </div>
    </div>

    <div class="button" style="margin-top: 76rem">
      <template>
        <el-button @click="$router.back()">返回</el-button>
      </template>
    </div>

    <el-dialog title="反馈详情" :visible.sync="dialogVisible">
      <div>
        {{ content }}
      </div>
      <span slot="footer">
				<el-button type="primary" @click="dialogVisible = false">关闭</el-button>
			</span>
    </el-dialog>

  </div>
</template>

<script>
import {mapState} from 'vuex'
import tools from "@/utils/tools";

export default {
	_config:{"route":{"path":"feedback","meta":{"title":"学生反馈"}}},
  data() {
    return {
      loading: false,
      dialogVisible: false,
      dataCont: '',
      content: '',
      search: null,
      searchConfig: [
        {prop: 'student_name', placeholder: '搜索讲解接收人'},
        {
          prop: 'school_id', tag: "select", placeholder: '筛选校区', options: [],
          label: 'name', value: 'id',
          change: (e, search) => this.schoolChange(e, search)
        },
        {
          prop: 'grade_id', tag: "select", placeholder: '筛选年级', options: [],
          label: 'name', value: 'id',
          change: (e, search) => this.gradeChange(e, search)
        },
        {
          prop: 'teaching_id', tag: "select", placeholder: '筛选班级', options: [],
          label: 'name', value: 'id',
        },
        {
          prop: 'status', tag: "select", placeholder: '筛选反馈状态',
          options: [
            {label: '未反馈', value: 2},
            {label: '已反馈', value: 4},
          ],
        },
      ],
      tableConfig: [
        {prop: 'school_name', label: '校区'},
        {prop: 'grade_info', label: '年级'},
        {prop: 'teaching_info', label: '班级'},
        {prop: 'student_name', label: '讲解接收人'},
        {prop: 'feedback_status', label: '反馈状态'},
        {prop: 'student_feedback', label: '反馈内容'},
        {prop: 'feedback_time', label: '反馈时间'},
        {
          prop: 'handle',
          label: '操作',
          width: '120rem',
          handle: true,
          render: (row) => row.student_feedback && row.student_feedback !== '-' ? ['详情'] : '-'
        }
      ],

      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,
    }
  },
  computed: {
    ...mapState(['page'])
  },
  created() {
    this.$store.commit('setPage', 1)
    this.getInfo()
    this.getData()
    this.getSchool()
  },
  methods: {
    getInfo() {
      this.$_axios2('api/question-group/student/base-group?id=' + this.$route.query.id).then(res => {
        this.dataCont = res.data.data
      })
    },

    getSchool() {
      this.$_axios2.get('api/common/school', {
        params: {
          level: 2,
          ispeiyou: 0
        }
      }).then(res => {
        let data = res.data.data;
        this.searchConfig[1].options = data.school
      })
    },

    schoolChange(e, search) {
      search.grade_id = ''
      search.teaching_id = ''
      this.searchConfig[3].options = []
      this.search = search
      let params = {school_id: search.school_id, level: 2, ispeiyou: 0}
      this.$_axios2.get('api/common/school', {params}).then(res => {
        let data = res.data.data;
        this.searchConfig[2].options = data.grade
      })
    },

    gradeChange(e, search) {
      search.teaching_id = ''
      this.search = search
      let params = {
        school_id: search.school_id,
        grade_id: search.grade_id,
        level: 2,
        ispeiyou: 0
      }
      this.$_axios2.get('api/common/school', {params}).then(res => {
        let data = res.data.data;
        this.searchConfig[3].options = data.teaching_data
      })
    },

    getData(type = 0) {
      let params = {...this.search, page: this.page, id: this.$route.query.id};
      if (type === 1) {
        params.export = 1
      } else {
        this.loading = true;
        this.tableData = []
      }
      this.$_axios2('api/question-group/student/group-student-list', {params}).then(res => {
        if (type === 1) {
          if (typeof res.data === 'string') tools.download('学生反馈', res.data)
        } else {
          let data = res.data.data
          this.tableData = data.list
          this.total = data.page.total
        }
      }).finally(() => {
        this.loading = false;
      })
    },

    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.search = val;
      this.getData()
    },

    handleEdit(row, text) {
      console.log(row,text,"<<<")
      this.dialogVisible = true;
      this.content = row.student_feedback
    },

    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit('setPage', val)
      //拉取数据
      this.getData()
    },

    onReset(){
      this.searchConfig[2].options = []
      this.searchConfig[3].options = []
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-transfer-panel__body {
  height: 350rem !important;
}

::v-deep .el-transfer-panel__list.is-filterable {
  height: 294rem !important;
}

.title-title {
  font-size: 18rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 60rem;
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 1rem solid #F7F7F7;
  border-radius: 10rem 10rem 0 0;
  background: #FFF;
  box-shadow: 2rem 2rem 2rem 1rem rgba(232, 237, 255, 0.16);
}

.title-bottom {
  box-sizing: border-box;
  margin-bottom: 24rem;
  //padding-top: 28rem;
  padding-bottom: 24rem;
  padding-left: 32rem;
  padding-right: 20rem;
  border-radius: 0 0 10rem 10rem;
  background: #FFF;
}

.body {
  margin: 24rem 0;

  & .body-title {
    font-size: 18rem;
    font-weight: bold;
    box-sizing: border-box;
    height: 78rem;
    padding-top: 19rem;
    padding-left: 24rem;
    color: rgba(0, 0, 0, 0.8);
    border-bottom: 1rem solid #F7F7F7;
    border-radius: 10rem 10rem 0 0;
    background: #FFFFFF;
    box-shadow: 2rem 2rem 2rem 1rem rgba(232, 237, 255, 0.16);
  }

  & .body-bottom {
    box-sizing: border-box;
    min-height: 148rem;
    padding: 0 24rem 24rem;
    border-radius: 0 0 10rem 10rem;
    background: #FFFFFF;
    box-shadow: 2rem 2rem 2rem 1rem rgba(232, 237, 255, 0.16);

    & .body-bottom-label {
      font-family: Microsoft YaHei-Regular, Microsoft YaHei, serif;
      font-size: 14rem;
      font-weight: 400;
      width: 96rem;
      color: rgba(0, 0, 0, 0.8);
    }
  }
}

.el-table__body .cell {
  white-space: normal;
  word-wrap: break-word;
}

.bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 220rem;
  height: 60rem;
}

.box-1 {
  width: 25%;
}

.box-2 {
  width: 33.3%;
}

.box-3 {
  width: 50%;
}

.box {
  font-size: 14rem;
  color: rgba(0, 0, 0, .6);
  margin-top: 24rem;

  span {
    margin-right: 30rem;
    color: rgba(0, 0, 0, .8);
  }
}

.knowledge {
  padding-top: 10rem;
  display: flex;
  align-items: center;

  .knowledge_box {
    display: flex;
    padding-top: 12rem;
    padding-left: 20rem;
    height: 60rem;
    background-color: #f4f4f4;

    .el-input {
      margin-right: 20rem;
      width: 250rem;

      &:last-child {
        width: 400rem;
      }
    }
  }

  .add_knowledge {
    display: flex;
    padding-left: 40rem;
  }
}

.span3 {
  display: inline-block;
  padding: 6rem 10rem;
  font-size: 14rem;
  border-radius: 6rem;
  background: #f7f7f7;
  line-height: 24rem;
  margin-right: 12rem;
  margin-top: 12rem;
}

.rich-box {
  background: #f7f7f7;
  padding: 12rem;
  border-radius: 6rem;
}

.span1 {
  font-size: 14rem;
  color: rgba(0, 0, 0, .6);
  margin-right: 20rem;
}

.span2 {
  font-size: 14rem;
  color: rgba(0, 0, 0, .8);
}

.describe-box {
  background: #f7f7f7;
  padding: 12rem;
  border-radius: 6rem;
  font-size: 14rem;
  line-height: 26rem;
}

.re {
  position: relative;

  &:before {
    content: "*";
    color: #f53f3f;
    margin-right: 4rem;
  }
}
</style>
